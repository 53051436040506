@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Work Sans";
    src: local("Work Sans"),
      url("../public/fonts/WorkSans-VariableFont_wght.ttf") format("truetype"),
      url("../public/fonts/WorkSans-Italic-VariableFont_wght.ttf")
        format("truetype");
    font-weight: 100 900;
    font-style: normal;
  }

  html {
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: #000;
    color: #fff;
  }
}
